<template>
  <div class="info-form-container">
    <el-form ref="form" :model="formData" label-width="170px">
      <div class="info-form-item-container">
        <div class="info-form-item-title">
          资质认证信息
        </div>
        <el-form-item label="经营类目" prop="supplierGoodsType" :rules="[
          {
            required: true,
            message: '请选择经营类目',
            trigger: 'change'
          }
        ]">
          <el-checkbox-group :value="formData.supplierGoodsType" @input="supplierGoodsTypeChange" :disabled="disabled || supplierGoodsTypeDisabled">
            <el-checkbox label="1">初级农产品</el-checkbox>
            <el-checkbox label="2">加工品</el-checkbox>
            <el-checkbox label="3">民宿</el-checkbox>
            <el-checkbox label="4">农家乐</el-checkbox>
            <el-checkbox label="5">餐饮</el-checkbox>
            <el-checkbox label="6">文创用品</el-checkbox>
            <el-checkbox label="7">旅行社</el-checkbox>
            <el-checkbox label="8">景点</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="business-category-container" v-for="qualification of formData.qualifications">
          <div class="business-category-container-title">
            {{ qualification.name }}
          </div>
          <template v-for="image of qualification.imageList">
            <el-form-item
              v-if="!image.isSelect"
              :label="image.suppliersImageName" 
              :prop="image.suppliersImageType"
              :key="image.suppliersImageType"
              :rules="[
                getRule(image)
              ]"
            >
              <div style="overflow: hidden;">
                <el-date-picker
                  :disabled="disabled"
                  v-if="image.type === 'time'"
                  v-model="formData[image.suppliersImageType]"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
                <upImage v-else type="string" :imageList.sync="formData[image.suppliersImageType]" :num="image.num" :disabled="disabled" />
              </div>
            </el-form-item>
            <template v-else>
              <el-form-item
                :label="image.name" 
              >
                <el-radio-group :value="formData[getSelectProp(image)]" @input="change($event, getSelectProp(image))" :disabled="disabled">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="formData[getSelectProp(image)] === '1'">
                <el-form-item
                  v-for="childImage of image.children"
                  :label="childImage.suppliersImageName" 
                  :prop="childImage.suppliersImageType"
                  :key="childImage.suppliersImageType"
                  :rules="[
                    getRule(childImage)
                  ]"
                >
                  <div style="overflow: hidden;">
                    <el-date-picker
                      :disabled="disabled"
                      v-if="childImage.type === 'time'"
                      v-model="formData[childImage.suppliersImageType]"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <upImage v-else  type="string" :imageList.sync="formData[childImage.suppliersImageType]" :num="childImage.num" :disabled="disabled" />
                  </div>
                </el-form-item>
              </template>
            </template>
          </template>
        </div>
      </div>
      <div class="info-form-item-container">
        <div class="info-form-item-title noRequired">
          荣誉证书
        </div>
        <el-form-item label="证书" prop="certificate">
          <div style="overflow: hidden;">
            <upImage type="string" :imageList.sync="formData.certificate" :num="12" :disabled="disabled" />
          </div>
        </el-form-item>
      </div>
      <div class="info-form-item-container">
        <div class="info-form-item-title">
          其他信息
        </div>
        <el-form-item label="自产自销" prop="select-9-1">
          <el-radio-group v-model="formData['select-9-1']" :disabled="disabled">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="formData['select-9-1'] === '0'" label="品牌授权证明" prop="9-1" :rules="[
            {
              required: true,
              message: '请上传品牌授权证明',
              trigger: 'change'
            }
          ]"
        >
          <div style="overflow: hidden;">
            <upImage type="string" :imageList.sync="formData['9-1']" :num="12" :disabled="disabled" />
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  import upImage from '@/components/common/upImage/index.vue'
  import qualifications from './qualifications'
  export default {
    props: ['data', 'disabled', 'supplierGoodsTypeDisabled'],
    components: {
      upImage
    },
    data() {
      return {
        formData: {
          supplierGoodsType: [], // 经营类目
          qualifications: [],
          certificate: '',
          'select-9-1': '1',
          '9-1': ''
        }
      }
    },
    watch: {
      data() {
        this.formatIntoData(this.data)
      }
    },
    methods: {
      change(data, key) {
        this.formData[key] = String(data)
      },
      setKey(data, key, value) {
        if (key in data) {
          data[key] = value
        } else {
          this.$set(data, key, value)
        }
      },
      formatIntoData(data) {
        this.formData.supplierGoodsType = data.supplierGoodsType ? data.supplierGoodsType.split(',') : []  // 经营类目
        this.formData.certificate = data.certificate   // 荣誉证书
        this.supplierGoodsTypeChange(this.formData.supplierGoodsType)
        // 回显qualifications
        for (const qualification of this.formData.qualifications) {
          for (const image of qualification.imageList) {
            if (image.isSelect) {
              // 回显选项qualification, 若存在子节点有选择就为选中，这里简单判断了第一个
              const firstImage = this.getResImage(image.children[0].suppliersImageType, data)
              this.setKey(this.formData, this.getSelectProp(image), firstImage && firstImage.suppliersImagePath ? '1' : '0')
              if (firstImage && firstImage.suppliersImagePath) {
                for (const childrenImage of image.children) {
                  const resImage = this.getResImage(childrenImage.suppliersImageType, data)
                  this.setKey(this.formData, childrenImage.suppliersImageType, resImage.suppliersImagePath)
                }
              }
            } else {
              // 回显普通qualification
              const echoImage = data.qualificationList.find((item)=> item.suppliersImageType === image.suppliersImageType)
              this.setKey(this.formData, image.suppliersImageType, echoImage ? echoImage.suppliersImagePath : '')
            }
          }
        }
        // 自产自销回填
        const echoImage = data.qualificationList.find((item)=> item.suppliersImageType === '9-1')
        if (echoImage && echoImage.suppliersImagePath) {
          this.formData['select-9-1'] = '0'
          this.formData['9-1'] = echoImage.suppliersImagePath
        }
      },
      supplierGoodsTypeChange(e) {
        this.formData.supplierGoodsType = e
        const list = qualifications.filter((qualification)=>{
          return this.formData.supplierGoodsType.includes(qualification.suppliersType)
        })
        this.formData.qualifications = list
        for (const qualification of list) {
          // form校验要与Prop和model对应，所以所有qualification的suppliersImagePath是映射到this.formData[suppliersImageType]上
          for (const image of qualification.imageList) {
            if (image.isSelect) {
              if (this.formData[this.getSelectProp(image)] !==  '1' && this.formData[this.getSelectProp(image)] !== '0') {
                this.setKey(this.formData, this.getSelectProp(image), image.default ?? '')
              }
            } else {
              if (this.formData[image.suppliersImageType] === undefined) {
                this.setKey(this.formData, image.suppliersImageType, '')
              }
            }
          }
        }
      },
      validator(callback, suppliersImageTypes) {
        // 联合校验规则
        const text = [];
        let pass = false;

        for (const suppliersImageType of suppliersImageTypes) {
          if (this.formData[suppliersImageType]) {
            pass = true
          }
          const image = this.getImage(suppliersImageType)
          text.push(image.suppliersImageName)
        }
        if (!pass) {
          return callback(new Error('请上传' + text.join('/') + '之一'))
        } 
        this.$refs['form'].clearValidate(suppliersImageTypes);
        return callback()
      },
      getSelectProp(image) {
        return  `select-${image.children.map((item)=> item.suppliersImageType).join('')}`
      },
      getImage(suppliersImageType) {
        let image = null
        for (const qualification of this.formData.qualifications) {
          for (const imageItem of qualification.imageList) {
            if (imageItem.suppliersImageType === suppliersImageType) {
              image = imageItem
              break
            }
          }
        }
        return image
      },
      getResImage(key, data) {
        return data.qualificationList.find((item)=> item.suppliersImageType === key)
      },
      getRule(image) {
        if (image.required === true) {
          return { required: true,  message: `请输入${image.suppliersImageName}`, trigger: 'change'}
        } else if (Array.isArray(image.required)) {
          return {
            validator: (rule, value, callback) => {
              this.validator(callback, image.required)
            }
          }
        }
        return {}
      },
      formSubmitData() {
        const list = []
        for (const qualification of this.formData.qualifications) {
          for (const image of qualification.imageList) {
            if (!image.isSelect) {
              let condition = this.formData[image.suppliersImageType];
              let suppliersImagePath = this.formData[image.suppliersImageType]
              condition && list.push({
                suppliersType: qualification.suppliersType,
                suppliersImageType: image.suppliersImageType,
                suppliersImageName: image.suppliersImageName,
                suppliersImagePath,
              })
            } else {
              if (this.formData[this.getSelectProp(image)] === '1') {
                // 若选项的值是1的话就填充提交数据
                for (const childImage of image.children) {
                  let condition = this.formData[childImage.suppliersImageType];
                  let suppliersImagePath = this.formData[childImage.suppliersImageType]
                  condition && list.push({
                    suppliersType: qualification.suppliersType,
                    suppliersImageType: childImage.suppliersImageType,
                    suppliersImageName: childImage.suppliersImageName,
                    suppliersImagePath,
                  })
                }
              }
            }
          }
        }
        if (this.formData['select-9-1'] === '0') {
          // 品牌授权单独处理
          list.push({
            suppliersImageType: '9-1',
            suppliersImageName: '品牌授权证明',
            suppliersImagePath: this.formData['9-1'],
          })
        }
        return {
          supplierGoodsType: this.formData.supplierGoodsType.join(','),
          qualificationList: list,
          certificate: this.formData.certificate
        }
      },
      submit() {
        return new Promise((resolve, reject)=>{
          this.$refs.form.validate((valid)=>{
            if (valid) {
              const data = this.formSubmitData()
              return resolve(data);
            }
            reject()
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .info-form-container {
    margin-top: 20px;
    width: 800px;
    .business-category-container {
      position: relative;
      .business-category-container-title {
        font-size: 14px;
        color: #1f2d3d;
        line-height: 40px;
      }
    }
    .info-form-item-container {
      width: 100%;
      .info-form-item-title {
        font-size: 16px;
        margin-bottom: 30px;
        margin-left: -30px;
        &.noRequired::after {
          content: '';
        }
        &::after{
          content: "*";
          color: red;
        }
      }
    }
  }
  .full {
    width: 100%;
  }
</style>