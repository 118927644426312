var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-container" }, [
    _c("div", { staticClass: "info-body-container" }, [
      _vm.stepsVisible
        ? _c("div", { staticClass: "info-steps-container" }, [
            _c(
              "div",
              { staticClass: "info-steps-content-container" },
              [
                _c(
                  "el-steps",
                  { attrs: { active: _vm.active, "align-center": "" } },
                  [
                    _c("el-step", { attrs: { title: "填写企业主体信息" } }),
                    _c("el-step", { attrs: { title: "填写品牌/资质信息" } }),
                    _c("el-step", { attrs: { title: "资质审核" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.active === 3 && _vm.isSettlePage && _vm.tipVisible
        ? _c("div", { staticClass: "info-tip" }, [
            _c("div", { staticClass: "info-tip-text" }, [
              _vm._v(_vm._s(_vm.tipTitle)),
            ]),
            this.formData.status === 4 && _vm.isSettlePage
              ? _c("div", [
                  _vm._v("恭喜开店成功，去"),
                  _c(
                    "span",
                    { staticClass: "to-logon", on: { click: _vm.toLogin } },
                    [_vm._v("登录")]
                  ),
                  _vm._v("开启致富之路"),
                ])
              : _c("div", [_vm._v(_vm._s(_vm.tipReason))]),
          ])
        : !_vm.isSettlePage &&
          _vm.tipVisible &&
          this.formData?.changeInfoAuditDto
        ? _c("div", { staticClass: "info-tip" }, [
            _c("div", { staticClass: "info-tip-text" }, [
              _vm._v(_vm._s(_vm.businessPageTip)),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.businessPageReason))]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "info-form-container" },
        [
          _c("infoPrincipal", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active === 1 || _vm.active === 3,
                expression: "active === 1 || active === 3",
              },
            ],
            ref: "infoPrincipal",
            attrs: { disabled: _vm.isDisabled, data: _vm.formData },
          }),
          _c("infoQualifications", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active === 2 || _vm.active === 3,
                expression: "active === 2 || active === 3",
              },
            ],
            ref: "infoQualifications",
            attrs: {
              disabled: _vm.isDisabled,
              supplierGoodsTypeDisabled: _vm.supplierGoodsTypeDisabled,
              data: _vm.formData,
            },
          }),
        ],
        1
      ),
    ]),
    _vm.type === "init"
      ? _c(
          "div",
          { staticClass: "info-footer-container" },
          [
            _vm.active === 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.active = 1
                      },
                    },
                  },
                  [_vm._v("上一步")]
                )
              : _vm._e(),
            !_vm.isDisabled
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v(_vm._s(_vm.submitText))]
                )
              : _vm.isDisabled && _vm.reviewFailed
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.toEdit } },
                  [_vm._v("去修改")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm.type === "add"
      ? _c(
          "div",
          { staticClass: "info-footer-container" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.modifySubmit } },
              [_vm._v("下一步")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "info-footer-container" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.cancel } },
              [_vm._v("返回")]
            ),
            _vm.type === "edit"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.modifySubmit },
                  },
                  [_vm._v("提交")]
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }