<template>
  <div class="info-form-container">
    <el-form ref="form" :model="formData" label-width="170px" :rules="rules">
      <div class="info-form-item-container">
        <div class="info-form-item-title">
          营业执照
        </div>
        <el-form-item label="主体类型" prop="supplierType">
          <el-select class="full" v-model="formData.supplierType" placeholder="请选择" :disabled="disabled">
            <el-option
              v-for="item in supplierTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营业执照" prop="0-3">
          <div style="overflow: hidden;">
            <upImage 
              type="string"
              :imageList.sync="formData['0-3']"
              :num="1"
              :disabled="disabled"
              @change="fullSettle"
            />
          </div>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="supplierPayAuthDto.uscc">
          <el-input v-model="formData.supplierPayAuthDto.uscc" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="企业名称" prop="supplierName">
          <el-input v-model="formData.supplierName" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="所属地区" prop="area">
          <el-cascader
            :disabled="disabled"
            ref="cascader"
            class="full"
            v-model="formData.area"
            :options="areaList"
            :props="areaProps"
          />
        </el-form-item>
        <el-form-item label="商家注册地址" prop="address">
          <el-input maxlength="30" v-model="formData.address" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="商家生产地址" prop="produceAddress">
          <el-input maxlength="30" v-model="formData.produceAddress" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
      </div>
      <div class="info-form-item-container">
        <div class="info-form-item-title">
          法人信息
        </div>
        <el-form-item label="身份证(头像面)" prop="0-1">
          <div style="overflow: hidden;">
            <upImage type="string" :imageList.sync="formData['0-1']" :num="1" :disabled="disabled" @change="fullLegalId" />
          </div>
        </el-form-item>
        <el-form-item label="身份证(国徽面)" prop="0-2">
          <div style="overflow: hidden;">
            <upImage type="string" :imageList.sync="formData['0-2']" :num="1" :disabled="disabled" />
          </div>
        </el-form-item>
        <el-form-item label="姓名" prop="supplierPayAuthDto.legalIdName">
          <el-input v-model="formData.supplierPayAuthDto.legalIdName" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="身份证号" prop="supplierPayAuthDto.legalIdCode">
          <el-input v-model="formData.supplierPayAuthDto.legalIdCode" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
      </div>
      <div class="info-form-item-container">
        <div class="info-form-item-title">
          基本信息
        </div>
        <el-form-item label="联系人姓名" prop="contact">
          <el-input v-model="formData.contact" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="联系方式" prop="contactMobile">
          <el-input v-model="formData.contactMobile" placeholder="请输入" :disabled="disabled" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  import upImage from '@/components/common/upImage/index.vue'
  import * as validate from '@/utils/validate'
  const rules = {
    supplierType: [ { required: true, message: '请选择主体类型', trigger: 'change'} ],
    '0-3': [ { required: true, message: '请上传营业执照', trigger: 'change' }],
    supplierName: [ { required: true,  message: '请输入企业名称', trigger: 'change'} ],
    address: [ { required: true,  message: '请输入商家注册地址', trigger: 'change' }],
    produceAddress: [ { required: true,  message: '请输入商家生产地址', trigger: 'change' }],
    area: [ { type: 'array', required: true, message: '请选择地区', trigger: 'change' }],
    'supplierPayAuthDto.legalIdName': [ { required: true,  message: '请输入姓名', trigger: 'change' }],
    'supplierPayAuthDto.legalIdCode': [ { required: true,  message: '请输入身份证号', trigger: 'change' }, validate.id],
    'supplierPayAuthDto.uscc': [ { required: true,  message: '请输入统一社会信用代码', trigger: 'change' }, validate.uscc],
    '0-1': [{ required: true, message: '请上传身份证正面', trigger: 'change' }],
    '0-2': [{ required: true, message: '请上传身份证反面', trigger: 'change' }],
    contact: [ { required: true,  message: '请输入姓名', trigger: 'change' }],
    contactMobile: [ { required: true,  message: '请输入手机号', trigger: 'change' }, validate.mobile],
  }
  export default {
    props: ['data', 'disabled'],
    components: {
      upImage
    },
    watch: {
      data (val) {
        this.formatIntoData(val)
      }
    },
    data() {
      return {
        rules,
        areaInit: [],
        supplierTypes: [{
          label: '个体工商户',
          value: 1
        },{
          label: '企业/公司',
          value: 2
        }],
        areaList: [],
        formData: {
          supplierId: '',
          supplierType: '',      // 主体类型
          '0-3': '',    // 营业执照
          supplierName: '',        // 企业名称
          address: '',    // 注册地址
          produceAddress: '',     // 生产地址
          supplierPayAuthDto: {
            legalIdName: '',   // 法人名字
            legalIdCode: '',   // 法人身份证号
            uscc: '',          // 统一社会信用代码
          },
          '0-1': '',  // 法人身份证正面照片
          '0-2': '',  // 法人身份证反面照片
          contact: '',   // 联系人名称
          contactMobile: '', // 联系人电话
          area: [],               // 所属地区
        },
        areaProps: {
          lazy: true,
          lazyLoad: async (node, resolve) => {
            if (node.data) {
              if (this.areaInit.includes(node.value)) {
                return resolve([])
              }
              if (node.level === 3) {
                return resolve([])
              }
              let list = await this.getAreaList(node.data.value)
              if (node.level === 2) {
                list = list.map((item)=> ({
                  ...item,
                  leaf: true
                }))
              }
              resolve(list)
            }
          }
        }
      }
    },
    methods: {
      submit() {
        return new Promise((resolve, reject)=>{
          this.$refs.form.validate((valid)=>{
            if (valid) {
              const data = this.formatSubmitData(this.formData)
              return resolve(data);
            }
            reject()
          })
        })
      },
      formatSubmitData() {
        const {
          supplierType,
          supplierName, 
          address,
          produceAddress,
          supplierPayAuthDto,
          contact,
          contactMobile,
        } = this.formData
        return {
          supplierType,
          supplierName, 
          address,
          produceAddress,
          supplierPayAuthDto,
          contact,
          contactMobile,
          province: this.formData.area[0],
          city: this.formData.area[1],
          district: this.formData.area[2],
          qualificationList: [
            {
              suppliersImagePath: this.formData['0-1'],
              suppliersImageName: '身份证正面',
              suppliersImageType: '0-1',
              suppliersType: '0'
            },
            {
              suppliersImagePath: this.formData['0-2'],
              suppliersImageName: '身份证反面',
              suppliersImageType: '0-2',
              suppliersType: '0'
            },
            {
              suppliersImagePath: this.formData['0-3'],
              suppliersImageName: '营业执照',
              suppliersImageType: '0-3',
              suppliersType: '0'
            }
          ]
        }
      },
      formatIntoData(data) {
        if (data.supplierType !== 0) {
          this.formData.supplierType = data.supplierType   
        }
           // 主体类型
        const fullQualificationList = ['0-1', '0-2', '0-3']
        for (const fullQualification of fullQualificationList) {
          for (const qualification of (data.qualificationList || [])) {
            if (qualification['suppliersImageType'] === fullQualification) {
              this.formData[fullQualification] = qualification['suppliersImagePath'] // 营业执照
            }
          }
        }
        this.formData.supplierName = data.supplierName        // 企业名称
        this.formData.address = data.address    // 注册地址
        this.formData.produceAddress = data.produceAddress     // 生产地址
        this.formData.supplierPayAuthDto = data.supplierPayAuthDto
        this.formData.contact = data.contact  // 联系人名称
        this.formData.contactMobile = data.contactMobile // 联系人电话
        const area = data.province ? [String(data.province), String(data.city), String(data.district)] : []
        this.getAreaListComplete(area)
        this.formData.area = area
        this.areaInit = area
      },
      async getAreaListComplete(area) {
        if (area.length > 0) {
          const provinceList = await this.getAreaList(0)
          const cityList = await this.getAreaList(area[0])
          const districtList = await this.getAreaList(area[1], true)
          for (const city of cityList) {
            if (city.value === String(area[1])) {
              city.children = districtList
            }
          }
          for (const province of provinceList) {
            if (province.value === String(area[0])) {
              province.children = cityList
            }
          }
          this.areaList = provinceList
          this.$nextTick(()=>{
            this.areaProps.lazy = true
            this.$refs.cascader.panel.initStore()
          })
        } else {
          this.areaList = await this.getAreaList(0)
        }
      },
      getAreaList(regionCode=0, leaf=false) {
        return this.$axios.get(this.$api.commonNew.getChildRegion, {
          params: {
            regionCode
          }
        }).then(res => {
          if (res.code === 0) {
            return res.data.map((item)=>({
              pid: item.pid,
              label: item.regionName,
              value: item.regionCode,
              leaf,
              type: item.type
            }))
          }
          return []
        })
      },
      fullSettle(data) {
        // 营业执照回显信息
        if (!data) {
          return
        }
        this.$axios.post(this.$api.settle.identifyBusinessLicense, {
          url: data
        }).then(res => {
          if (res.code === 0) {
            if(res.data.wordsResult && res.data.wordsResult.length > 0) {
              const result = res.data.wordsResult[0]
              result['单位名称'] && !['', '无'].includes(result['单位名称']['words']) && (this.formData.supplierName = result['单位名称']['words']);
              result['社会信用代码'] && !['', '无'].includes(result['社会信用代码']['words']) && (this.formData.supplierPayAuthDto.uscc = result['社会信用代码']['words']);
              result['法人'] && !['', '无'].includes(result['法人']['words']) && (this.formData.supplierPayAuthDto.legalIdName = result['法人']['words']);
              result['地址'] && !['', '无'].includes(result['地址']['words']) && (this.formData.address = result['地址']['words']);
            }
          }
        })
      },
      fullLegalId(data) {
        // 法人回显信息
        if (!data) {
          return
        }
        this.$axios.post(this.$api.settle.identifyIdCard, {
          detectQuality: 'true',
          idCardSide: 'front',
          url: data
        }).then(res => {
          if (res.code === 0) {
            if(res.data.wordsResult && res.data.wordsResult.length > 0) {
              const result = res.data.wordsResult[0]
              result['姓名'] && !['', '无'].includes(result['姓名']['words']) && (this.formData.supplierPayAuthDto.legalIdName = result['姓名']['words']);
              result['公民身份号码'] && !['', '无'].includes(result['公民身份号码']['words']) && (this.formData.supplierPayAuthDto.legalIdCode = result['公民身份号码']['words']);
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-form-container {
    margin-top: 20px;
    width: 800px;
    .info-form-item-container {
      width: 100%;
      .info-form-item-title {
        font-size: 16px;
        margin-bottom: 30px;
        margin-left: -30px;
        &::after{
          content: "*";
          color: red;
        }
      }
    }
  }
  .full {
    width: 100%;
  }
</style>