var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-form-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            "label-width": "170px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title" }, [
                _vm._v(" 营业执照 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "主体类型", prop: "supplierType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full",
                      attrs: { placeholder: "请选择", disabled: _vm.disabled },
                      model: {
                        value: _vm.formData.supplierType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "supplierType", $$v)
                        },
                        expression: "formData.supplierType",
                      },
                    },
                    _vm._l(_vm.supplierTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "0-3" } },
                [
                  _c(
                    "div",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c("upImage", {
                        attrs: {
                          type: "string",
                          imageList: _vm.formData["0-3"],
                          num: 1,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "update:imageList": function ($event) {
                            return _vm.$set(_vm.formData, "0-3", $event)
                          },
                          "update:image-list": function ($event) {
                            return _vm.$set(_vm.formData, "0-3", $event)
                          },
                          change: _vm.fullSettle,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "统一社会信用代码",
                    prop: "supplierPayAuthDto.uscc",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.supplierPayAuthDto.uscc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.supplierPayAuthDto, "uscc", $$v)
                      },
                      expression: "formData.supplierPayAuthDto.uscc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "企业名称", prop: "supplierName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.supplierName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "supplierName", $$v)
                      },
                      expression: "formData.supplierName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属地区", prop: "area" } },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticClass: "full",
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.areaList,
                      props: _vm.areaProps,
                    },
                    model: {
                      value: _vm.formData.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "area", $$v)
                      },
                      expression: "formData.area",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商家注册地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "30",
                      placeholder: "请输入",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formData.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商家生产地址", prop: "produceAddress" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "30",
                      placeholder: "请输入",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formData.produceAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "produceAddress", $$v)
                      },
                      expression: "formData.produceAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title" }, [
                _vm._v(" 法人信息 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "身份证(头像面)", prop: "0-1" } },
                [
                  _c(
                    "div",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c("upImage", {
                        attrs: {
                          type: "string",
                          imageList: _vm.formData["0-1"],
                          num: 1,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "update:imageList": function ($event) {
                            return _vm.$set(_vm.formData, "0-1", $event)
                          },
                          "update:image-list": function ($event) {
                            return _vm.$set(_vm.formData, "0-1", $event)
                          },
                          change: _vm.fullLegalId,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证(国徽面)", prop: "0-2" } },
                [
                  _c(
                    "div",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c("upImage", {
                        attrs: {
                          type: "string",
                          imageList: _vm.formData["0-2"],
                          num: 1,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "update:imageList": function ($event) {
                            return _vm.$set(_vm.formData, "0-2", $event)
                          },
                          "update:image-list": function ($event) {
                            return _vm.$set(_vm.formData, "0-2", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "supplierPayAuthDto.legalIdName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.supplierPayAuthDto.legalIdName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.supplierPayAuthDto,
                          "legalIdName",
                          $$v
                        )
                      },
                      expression: "formData.supplierPayAuthDto.legalIdName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证号",
                    prop: "supplierPayAuthDto.legalIdCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.supplierPayAuthDto.legalIdCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.supplierPayAuthDto,
                          "legalIdCode",
                          $$v
                        )
                      },
                      expression: "formData.supplierPayAuthDto.legalIdCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title" }, [
                _vm._v(" 基本信息 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "联系人姓名", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contact", $$v)
                      },
                      expression: "formData.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "contactMobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: _vm.disabled },
                    model: {
                      value: _vm.formData.contactMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contactMobile", $$v)
                      },
                      expression: "formData.contactMobile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }