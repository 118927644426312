<template>
  <div class="info-container">
    <div class="info-body-container">
      <div class="info-steps-container" v-if="stepsVisible">
        <div class="info-steps-content-container">
          <el-steps :active="active" align-center>
            <el-step title="填写企业主体信息"></el-step>
            <el-step title="填写品牌/资质信息"></el-step>
            <el-step title="资质审核"></el-step>
          </el-steps>
        </div>
      </div>
      <div class="info-tip" v-if="active === 3 && isSettlePage && tipVisible">
        <div class="info-tip-text">{{ tipTitle }}</div>
        <div v-if="this.formData.status === 4 && isSettlePage">恭喜开店成功，去<span class="to-logon" @click="toLogin">登录</span>开启致富之路</div>
        <div v-else>{{ tipReason }}</div>
      </div>
      <div class="info-tip" v-else-if="!isSettlePage && tipVisible && this.formData?.changeInfoAuditDto">
        <div class="info-tip-text">{{ businessPageTip }}</div>
        <div>{{ businessPageReason }}</div>
      </div>
      <div class="info-form-container">
        <infoPrincipal :disabled="isDisabled" v-show="active === 1 || active === 3" :data="formData" ref="infoPrincipal" />
        <infoQualifications :disabled="isDisabled" :supplierGoodsTypeDisabled="supplierGoodsTypeDisabled" v-show="active === 2 || active === 3" :data="formData" ref="infoQualifications" />
      </div>
    </div>
    <div class="info-footer-container" v-if="type === 'init'">
      <el-button v-if="active === 2" type="primary" @click="active = 1">上一步</el-button>
      <el-button type="primary" @click="submit" v-if="!isDisabled">{{ submitText }}</el-button>
      <el-button type="primary" @click="toEdit" v-else-if="isDisabled && reviewFailed">去修改</el-button>
    </div>
    <div class="info-footer-container" v-else-if="type === 'add'">
      <el-button type="primary" @click="modifySubmit">下一步</el-button>
    </div>
    <div class="info-footer-container" v-else>
      <el-button type="primary" @click="cancel">返回</el-button>
      <el-button type="primary" v-if="type === 'edit'" @click="modifySubmit">提交</el-button>
    </div>
  </div>
</template>

<script>
  import upImage from '@/components/common/upImage/index.vue'
  import infoPrincipal from './infoPrincipal.vue'
  import infoQualifications from './infoQualifications.vue'

  export default {
    components: {
      upImage,
      infoPrincipal,
      infoQualifications
    },
    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    watch: {
      data(val) {
        this.formData = val
      }
    },
    data() {
      return {
        type: 'init',
        stepsVisible: true,
        tipVisible: true,
        supplierGoodsTypeDisabled: false,
        active: 1,
        mobile: '',
        isEdit: false,
        infoPrincipalData: {},
        formData: {
          supplierId: '',
          city: '',
          district: '',
          province: '',
          supplierGoodsType: '',
          qualificationList: [],
          supplierName: '',
          supplierType: '',
          address: '',
          status: '',
          certificate: '',
          contact: '',
          contactMobile: '',
          produceAddress: '',
          supplierPayAuthDto: {
            legalIdCode: '',
            legalIdName: '',
            uscc: ''
          }
        }
      }
    },
    computed: {
      isSettlePage() {
        // 是否商家入驻页面
        return this.type === 'init'
      },
      businessPageTip() {
        let text = ''
        if (this.formData?.changeInfoAuditDto?.auditResult === 0){
          text = "审核中"
        } else if (this.formData?.changeInfoAuditDto?.auditResult === 1) {
          text = '审核通过';
        } else if (this.formData?.changeInfoAuditDto?.auditResult === 2) {
          text = '审核不通过';
        }
        return text
      },
      businessPageReason() {
        let text = ''
        if (this.formData?.changeInfoAuditDto?.auditResult === 2) {
          text = this.formData.changeInfoAuditDto.auditNote
        }

        return text
      },
      submitText() {
        let text = '保存并进行下一步'
        if (this.active === 2 || this.active === 3) {
          text = '提交审核'
        }
        return text
      },
      isVerify() {
        return this.formData.status === 2 || this.formData.status === 6
      },
      reviewFailed() {
        return this.formData.status === 3 || this.formData.status === 7
      },
      tipTitle() {
        let text = ''
        if (this.isVerify) {
          text = '提交成功，请等待审核'
        } else if (this.formData.status === 4) {
          text = '审核通过'
        } else if (this.reviewFailed) {
          text = '抱歉您的审核未通过'
        } else if (this.formData.status === 5) {
          text = '账号已禁用'
        }
        return text
      },
      tipReason() {
        let text = ''
        if (this.formData.status === 2 || this.formData.status === 6) {
          text = '预计3-7个工作日审核完成'
        } else if (this.formData.status === 3 || this.formData.status === 7) {
          if (Array.isArray(this.formData.supplierAuditDtoList)) {
            text = this.formData.supplierAuditDtoList[0]['auditNote']
          }
        }
        return text
      },
      isDisabled() {
        return (this.active === 3 && this.isEdit === false || this.type === 'read') && (this.type !== 'edit' && this.type !== 'add')
      }
    },
    methods: {
      toEdit() {
        this.isEdit = true;
        this.active = 1;
      },
      back() {
        this.$confirm('是否保存草稿？', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        }).then(()=>{
          this.submit().finally(()=>{
            this.$router.back()
          })
        }).catch(()=>{
          this.$router.back()
        })
      },
      toLogin() {
        this.$router.push({
          path: '/login'
        })
      },
      mergeSubmitData(mergeData) {
        const qualificationList = Array.isArray(this.formData.qualificationList) ? this.formData.qualificationList : []
        const list = qualificationList.filter((qualification)=>{
          return !mergeData.qualificationList.find((mergeDataQualification)=>{
            return qualification.suppliersImageType === mergeDataQualification.suppliersImageType
          })
        })
        
        const mergeQualificationList = list.concat(mergeData.qualificationList).sort((i1, i2)=> i1.suppliersImageType - i2.suppliersImageType)
        this.formData = { ...this.formData, ...mergeData }
        this.formData.qualificationList = mergeQualificationList
        return this.formData
      },
      async submit() {
        if (this.active === 1) {
          this.$refs.infoPrincipal.submit().then((params)=>{
            this.infoPrincipalData = params
            const data = this.mergeSubmitData(params);
            this.$axios.post(this.$api.settle.saveDraft, data).then( r => {
              if (r.code === 0) {
                this.formData = data;
                this.active = 2
              }
            })
          })
        } else if (this.active === 2) {
          this.$refs.infoQualifications.submit().then((params)=>{
            const qualificationList = params.qualificationList.concat(this.infoPrincipalData.qualificationList)
            this.formData = {...this.formData,  ...params}
            this.formData.qualificationList = qualificationList
            this.$axios.post(this.$api.settle.submitReview, this.formData).then( r => {
              if (r.code === 0) {
                this.$message.success('提交成功')
                this.isEdit = false
                this.refresh()
                this.$emit('submit')
              }
            })
          })
        }
      },
      setActive() {
        if (this.formData.status === 0 || this.formData.status === 1) {
          this.active = 1;
        } else if (this.formData.status > 1) {
          this.active = 3
        }
      },
      refresh() {
        this.$axios.get(this.$api.settle.getReviewInfoBySupplierId, {
          params: {
            supplierId: this.$route.query.id,
          }
        }).then( r => {
          if (r.code === 0) {
            this.formData = r.data
            this.setActive()
          }
        })
      },
      modifySubmit() {
        Promise.all([new Promise((resolve, reject)=>{
            this.$refs.infoPrincipal.submit().then((params)=>{
              resolve(params)
            }).catch(reject)
          }), new Promise((resolve, reject)=>{
            this.$refs.infoQualifications.submit().then((params)=>{
              resolve(params)
            }).catch(reject)
          })]).then(([params1, params2])=>{
            const mergeParams = {...params1, ...params2, qualificationList: params1.qualificationList.concat(params2.qualificationList)}
            const data = this.mergeSubmitData(mergeParams)
            this.$axios.post(this.$api.supplier.change_info, data).then( r => {
              if (r.code === 0) {
                this.$message.success('提交成功')
                this.refresh()
                this.$emit('submit')
              }
            })
          })
      },
      cancel() {
        this.$router.back()
      },
    },
    mounted() {
      this.refresh()
      this.type = typeof this.$route.query.type === 'string' ? this.$route.query.type : this.type
      this.tipVisible = typeof this.$route.query.tipVisible === 'string' ? this.$route.query.tipVisible === '1' : this.tipVisible
      this.stepsVisible = typeof this.$route.query.stepsVisible === 'string' ? this.$route.query.stepsVisible === '1' : this.stepsVisible
      if (this.type === 'edit' || this.type === 'add') {
        // 经营类目不让改
        this.supplierGoodsTypeDisabled = true
      }
    }
  }
</script>
<style lang="scss" scoped>
  .info-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .info-body-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .info-tip {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f9f5e9;
      padding: 10px;
      .to-logon {
        text-decoration: underline;
        cursor: pointer;
      }
      .info-tip-text {
        margin-bottom: 5px;
      }
    }
    .info-steps-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .back {
        position: absolute;
        top: 10px;
        font-size: 16px;
        left: 10px;
        cursor: pointer;
      }
      .info-steps-content-container {
        width: 600px;
      }
    }
    .info-footer-container {
      margin: 20px 0;
    }
  }
</style>