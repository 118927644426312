var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-form-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.formData, "label-width": "170px" } },
        [
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title" }, [
                _vm._v(" 资质认证信息 "),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经营类目",
                    prop: "supplierGoodsType",
                    rules: [
                      {
                        required: true,
                        message: "请选择经营类目",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: {
                        value: _vm.formData.supplierGoodsType,
                        disabled: _vm.disabled || _vm.supplierGoodsTypeDisabled,
                      },
                      on: { input: _vm.supplierGoodsTypeChange },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("初级农产品"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("加工品"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("民宿"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "4" } }, [
                        _vm._v("农家乐"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "5" } }, [
                        _vm._v("餐饮"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "6" } }, [
                        _vm._v("文创用品"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "7" } }, [
                        _vm._v("旅行社"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "8" } }, [
                        _vm._v("景点"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.formData.qualifications, function (qualification) {
                return _c(
                  "div",
                  { staticClass: "business-category-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "business-category-container-title" },
                      [_vm._v(" " + _vm._s(qualification.name) + " ")]
                    ),
                    _vm._l(qualification.imageList, function (image) {
                      return [
                        !image.isSelect
                          ? _c(
                              "el-form-item",
                              {
                                key: image.suppliersImageType,
                                attrs: {
                                  label: image.suppliersImageName,
                                  prop: image.suppliersImageType,
                                  rules: [_vm.getRule(image)],
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { overflow: "hidden" } },
                                  [
                                    image.type === "time"
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            "value-format": "yyyy-MM-dd",
                                          },
                                          model: {
                                            value:
                                              _vm.formData[
                                                image.suppliersImageType
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                image.suppliersImageType,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData[image.suppliersImageType]",
                                          },
                                        })
                                      : _c("upImage", {
                                          attrs: {
                                            type: "string",
                                            imageList:
                                              _vm.formData[
                                                image.suppliersImageType
                                              ],
                                            num: image.num,
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            "update:imageList": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.formData,
                                                image.suppliersImageType,
                                                $event
                                              )
                                            },
                                            "update:image-list": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.formData,
                                                image.suppliersImageType,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : [
                              _c(
                                "el-form-item",
                                { attrs: { label: image.name } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        value:
                                          _vm.formData[
                                            _vm.getSelectProp(image)
                                          ],
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.change(
                                            $event,
                                            _vm.getSelectProp(image)
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "0" } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.formData[_vm.getSelectProp(image)] === "1"
                                ? _vm._l(image.children, function (childImage) {
                                    return _c(
                                      "el-form-item",
                                      {
                                        key: childImage.suppliersImageType,
                                        attrs: {
                                          label: childImage.suppliersImageName,
                                          prop: childImage.suppliersImageType,
                                          rules: [_vm.getRule(childImage)],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: { overflow: "hidden" },
                                          },
                                          [
                                            childImage.type === "time"
                                              ? _c("el-date-picker", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData[
                                                        childImage
                                                          .suppliersImageType
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        childImage.suppliersImageType,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData[childImage.suppliersImageType]",
                                                  },
                                                })
                                              : _c("upImage", {
                                                  attrs: {
                                                    type: "string",
                                                    imageList:
                                                      _vm.formData[
                                                        childImage
                                                          .suppliersImageType
                                                      ],
                                                    num: childImage.num,
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    "update:imageList":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.formData,
                                                          childImage.suppliersImageType,
                                                          $event
                                                        )
                                                      },
                                                    "update:image-list":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.formData,
                                                          childImage.suppliersImageType,
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  })
                                : _vm._e(),
                            ],
                      ]
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title noRequired" }, [
                _vm._v(" 荣誉证书 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "证书", prop: "certificate" } },
                [
                  _c(
                    "div",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c("upImage", {
                        attrs: {
                          type: "string",
                          imageList: _vm.formData.certificate,
                          num: 12,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "update:imageList": function ($event) {
                            return _vm.$set(_vm.formData, "certificate", $event)
                          },
                          "update:image-list": function ($event) {
                            return _vm.$set(_vm.formData, "certificate", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "info-form-item-container" },
            [
              _c("div", { staticClass: "info-form-item-title" }, [
                _vm._v(" 其他信息 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "自产自销", prop: "select-9-1" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.formData["select-9-1"],
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "select-9-1", $$v)
                        },
                        expression: "formData['select-9-1']",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formData["select-9-1"] === "0"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "品牌授权证明",
                        prop: "9-1",
                        rules: [
                          {
                            required: true,
                            message: "请上传品牌授权证明",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { overflow: "hidden" } },
                        [
                          _c("upImage", {
                            attrs: {
                              type: "string",
                              imageList: _vm.formData["9-1"],
                              num: 12,
                              disabled: _vm.disabled,
                            },
                            on: {
                              "update:imageList": function ($event) {
                                return _vm.$set(_vm.formData, "9-1", $event)
                              },
                              "update:image-list": function ($event) {
                                return _vm.$set(_vm.formData, "9-1", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }