// 资质列表
// 资质类型: 0：基础（身份证、营业执照） 1：初级农产品 2：农加工产品 3：民宿 4：农家乐 5：餐饮 6：文创用品 7：旅游社 8：景点
// 资质类型：0-1：身份证正面，0-2：身份证反面，0-3：营业执照；
// 1-1：合格证/绿色食品认证/农产品检测证明； 1-2：动物检疫合格证明(非必填)
// 2-1：食品生产/经营许可证；
// 3-1：卫生许可证，3-2：特种行业许可证，3-3：食品经营许可证(非必填)
// 4-1：卫生许可证，4-2：特种行业许可证(非必填)，4-3：食品经营许可证(非必填)；
// 5-1：卫生许可证，5-2：食品经营许可证；
// 6-1：经营许可证；
// 7-1：经营许可证，7-2：组织机构代码证
// 8-1：经营许可证，8-2：卫生许可证，8-3：特种行业许可证(非必填)，8-4：食品经营许可证(非必填)；
const qualificationList = [
  {
    suppliersType: '1',
    name: '初级农产品',
    imageList: [
      {
        suppliersImageType: '1-1',
        suppliersImageName: '农产品合格证',
        suppliersImagePath: '',
        required: ['1-1', '1-2', '1-3'],
        num: 1,
      },
      {
        suppliersImageType: '1-2',
        suppliersImageName: '绿色食品认证',
        suppliersImagePath: '',
        required: ['1-1', '1-2', '1-3'],
        num: 1,
      },
      {
        suppliersImageType: '1-3',
        suppliersImageName: '农产品检测证明',
        suppliersImagePath: '',
        required: ['1-1', '1-2', '1-3'],
        num: 12,
      },
      {
        name: '初级肉禽类农产品',
        isSelect: true,
        children: [
          {
            suppliersImageName: '动物检疫合格证明',
            suppliersImageType: '1-4',
            suppliersImagePath: '',
            required: true,
            num: 12
          }
        ],
        default: '0',
      }
    ]
  },
  {
    suppliersType: '2',
    name: '农加工产品',
    imageList: [
      {
        suppliersImageName: '食品生产证',
        suppliersImageType: '2-1',
        suppliersImagePath: '',
        required: ['2-1', '2-2'],
        num: 1
      },
      {
        suppliersImageName: '经营许可证',
        suppliersImageType: '2-2',
        suppliersImagePath: '',
        required: ['2-1', '2-2'],
        num: 1
      }
    ]
  },
  {
    suppliersType: '3',
    name: '民宿',
    imageList: [
      {
        suppliersImageName: '卫生许可证',
        suppliersImageType: '3-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        suppliersImageName: '特种行业许可证',
        suppliersImageType: '3-2',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        name: '提供餐饮/食品',
        isSelect: true,
        children: [
          {
            suppliersImageName: '食品经营许可证',
            suppliersImageType: '3-3',
            suppliersImagePath: '',
            required: true,
            num: 1
          }
        ],
        default: '0',
      }
    ]
  },
  {
    suppliersType: '4',
    name: '农家乐',
    imageList: [
      {
        suppliersImageName: '卫生许可证',
        suppliersImageType: '4-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        name: '提供住宿',
        isSelect: true,
        children: [
          {
            suppliersImageName: '特种行业许可证',
            suppliersImageType: '4-2',
            suppliersImagePath: '',
            required: true,
            num: 1
          },
        ],
        default: '0',
      },
      {
        name: '提供餐饮/食品',
        isSelect: true,
        children: [
          {
            suppliersImageName: '食品经营许可证',
            suppliersImageType: '4-3',
            suppliersImagePath: '',
            required: true,
            num: 1
          }
        ],
        default: '0',
      },
    ]
  },
  {
    suppliersType: '5',
    name: '餐饮',
    imageList: [
      {
        suppliersImageName: '卫生许可证',
        suppliersImageType: '5-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        suppliersImageName: '食品经营许可证',
        suppliersImageType: '5-2',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        suppliersImageName: '经营人员健康证',
        suppliersImageType: '5-3',
        suppliersImagePath: '',
        required: false,
        num: 12
      }
    ]
  },
  {
    suppliersType: '6',
    name: '文创用品 ',
    imageList: [
      {
        suppliersImageName: '经营许可证',
        suppliersImageType: '6-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
    ]
  },
  {
    suppliersType: '7',
    name: '旅行社',
    imageList: [
      {
        suppliersImageName: '经营许可证',
        suppliersImageType: '7-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        suppliersImageName: '组织机构代码证',
        suppliersImageType: '7-2',
        suppliersImagePath: '',
        required: false,
        num: 1
      },
      {
        suppliersImageName: '旅行社责任保险',
        suppliersImageType: '7-3',
        suppliersImagePath: '',
        required: false,
        num: 12
      },
      {
        name: '有车辆经营业务',
        isSelect: true,
        children: [
          {
            suppliersImageName: '道路运输经营许可证',
            suppliersImageType: '7-4',
            suppliersImagePath: '',
            required: true,
            num: 1
          },
          {
            suppliersImageName: '车辆保险协议书',
            suppliersImageType: '7-5',
            suppliersImagePath: '',
            required: true,
            num: 12
          },
          {
            suppliersImageName: '最新保险有效期',
            suppliersImageType: '7-6',
            suppliersImagePath: '',
            required: true,
            type: 'time',
            num: 1
          }
        ],
        default: '0',
      }
    ]
  },
  {
    suppliersType: '8',
    name: '景点',
    imageList: [
      {
        suppliersImageName: '经营许可证',
        suppliersImageType: '8-1',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        suppliersImageName: '卫生许可证',
        suppliersImageType: '8-2',
        suppliersImagePath: '',
        required: true,
        num: 1
      },
      {
        name: '提供住宿',
        isSelect: true,
        children: [
          {
            suppliersImageName: '特种行业许可证',
            suppliersImageType: '8-3',
            suppliersImagePath: '',
            required: true,
            num: 1
          },
        ],
        default: '0',
      },
      {
        name: '提供餐饮/食品',
        isSelect: true,
        children: [
          {
            suppliersImageName: '食品经营许可证',
            suppliersImageType: '8-4',
            suppliersImagePath: '',
            required: true,
            num: 1
          }
        ],
        default: '0',
      }, {
        suppliersImageName: '质量等级评定',
        suppliersImageType: '8-5',
        suppliersImagePath: '',
        required: false,
        num: 1
      },
    ]
  },
]
export default qualificationList